import React from "react";
import {Link} from 'react-scroll';
function Header(props) {

  return (    
    <div className="header" >
    <div className="navButtons">
      <img className="logo" src="/logoblancotelfly.png" alt="Logo telfly"></img> 
      <div>
        <Link   id='iniciob' className="navButton" activeClass="active" to="inicio" spy={true} smooth={true}  duration={500}>
        Inicio
        </Link>
      </div>
      <div>
        <Link   id='inicion' className="navButton" activeClass="active" to="nosotros" spy={true} smooth={true}  duration={500}>
          Nosotros
        </Link>
      </div>
      <div>
        <Link   id='iniciot' className="navButton" activeClass="active" to="tarifas" spy={true} smooth={true}  duration={500}>
        Tarifas
        </Link>
      </div>
      <div>
        <Link id='inicioc' className="navButton" activeClass="active" to="contactenos" spy={true} smooth={true} duration={500}>
        Contáctenos
        </Link>
      </div>
      </div>
    </div>
  );
}

export default Header;