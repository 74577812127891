function Nosotros() {
    return (
      <div className="nosotros">
        <br/>
        <br/>
          <div className="miYVi">
            <h1>Misión</h1><br/>
            <p className="textoPa" style={{color:'lightgray'}}>Nuestra empresa esta en la rama de las telecomunicaciones,
            donde queremos ser la empresa número uno, en ofrecer el mejor servicio a aquellas regiones donde se encuentren
            nuestras oficinas, brindando a nuestros usuarios un muy buen producto con muy buena atencion.</p>
          </div><br/>
          <div className="miYVi">
            <h1>Visión</h1><br/>
            <p className="textoPa" style={{color:'lightgray'}}>Que en lugar donde estemos ubicados nos reconozcan, por ser quien ofrece mejor servicio, quien tiene más
            usuarios en telecomunicaciones y que nos reconozcan como la
            mejor en calidad, precio y atención. </p>
          </div> 
      </div>
    );
  }
  
  export default Nosotros;