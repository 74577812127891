function Tarifas() {
    return (
        
      <div className="tarifas">
          <br/>
          <br/>
          <div className="miYVi" >
                <br/>
                <div style={{display:'block'}}>
                <img className="icono" src="/wifi-router.png" alt="Tarifas telfly"></img><br/>
                <h2 >Internet de 10MB </h2></div><br/>
                <div className="textoPa" style={{color:'lightgray'}}>
                <h4>Simetrico ¡es igual de subida y bajada!</h4><br/>
                <h3 > Tan solo 65.000$</h3><br/>
                <h5>Más televisión de 60 canales 75.000$</h5><br/>
                </div>
                
          </div>
          <div className="miYVi" > 
          <br/> 
          <div style={{display:'block'}}>
                <img className="icono" src="/wifi-router.png" alt="Tarifas telfly"></img><br/>
                <h2 >Internet de 20MB </h2></div><br/>
                <div className="textoPa" style={{color:'lightgray'}}>
                <h4>Simetrico ¡es igual de subida y bajada!</h4><br/>
                <h3> Tan solo 75.000$</h3><br/>
                <h5>Más televisión de 60 canales 85.000$</h5><br/>
                </div>
                         
            </div>
            <div className="miYVi" > 
          <br/> 
          <div style={{display:'block'}}>
                <img className="icono" src="/wifi-router.png" alt="Tarifas telfly"></img><br/>
                <h2 >Internet de 50MB </h2></div><br/>
                <div className="textoPa" style={{color:'lightgray'}}>
                <h4>Simetrico ¡es igual de subida y bajada!</h4><br/>
                <h3> Tan solo 100.000$</h3><br/>
                <h5>Más televisión de 60 canales 120.000$</h5><br/>
                </div>
                         
            </div>
              
        </div>
    );
  }
  
  export default Tarifas;