function Somos() {
    return (
      <div className="somos">
          <div className="textoH">
            <br/>
            <img src="/logoblancotelfly.png" style={{height:'170px'}} alt="Logo telfly"></img>
           <p> Internet que vuela</p><br/>
           <p className="tituloP">¡Brindamos los mejores planes de internet!</p>
           <br/>
           <a  href="https://telfly.speedtestcustom.com/" target="_blank" rel="noreferrer"><h3 className="button">Test de velocidad Telfly</h3></a>
          </div>      
      </div>
    );
  }
export default Somos;