import '../App.css';
import Header from './header.js';
import Somos from './somos.js';
import Nosotros from './nosotros.js';
import Tarifas from './tarifas.js';
import Contactenos from './contactenos.js';

function Aplicacion()
 {  
  return (
    <div className="App">    
    <Header/>  
      <section name='inicio' >        
        <Somos/>
      </section>
      <section name='nosotros'>
      <div className="area" >
            <ul className="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
            </ul>
            </div>   
        <Nosotros/>
      </section>
      <section name='tarifas'>
      <div className="area" >
            <ul className="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
            </ul>
            </div>   
        <Tarifas/>        
      </section>
      <section name='contactenos'>
        <Contactenos/>
      </section>
    </div>
  );
}

export default Aplicacion;
