import {makeStyles,TextField, Button,MenuItem} from '@material-ui/core';
import React from 'react';
import emailjs from 'emailjs-com';
const useStyles = makeStyles((theme) => ({
  root: {
    '& label.Mui-focused': {
      color: 'darkgray',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'darkgray',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'darkgray',
      },
      '&:hover fieldset': {
        borderColor: 'darkgray',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'darkgray',
      },
    },
  },
  button:
  {
    borderColor:'darkgray',
    color:'darkgray',
  }
}));
function Contactenos() {
    const [value,setValue] = React.useState();
    const [nombre,setNombre]= React.useState();
    const [ciudad,setCiudad]= React.useState();
    const [telefono,setTelefono]= React.useState();
    const [mensaje,setMensaje]= React.useState();
    const enviarCorreo=()=>
    {
      
      if(!nombre|| !mensaje || !ciudad || !telefono|| !value)
      {
        alert('Llena todos los campos');
      }
      else
      {
        var templateParams = {
          kind: value,
          nombre: nombre,
          tel: telefono,
          ciudad: ciudad,
          message: mensaje  
      };
      emailjs.send('gmailT', 'template_hbni8y9', templateParams, 'user_sPOhMwwSQV2KtkVUMx98Z')
      .then(function(response) {
       alert('Correo enviado, recibiras respuesta de nosotros al número brindado.');
      }, function(error) {
       alert('Ups no pudimos enviarlo, intenta más tarde o llamános.');
      });
      setValue('');
      setNombre('');
      setCiudad('');
      setTelefono('');
      setMensaje('');
      }
      
    } 
    const handleChange= (event)=>
    {
        setValue(event.target.value);
    }
    const nombreChange= (event)=>
    {
        setNombre(event.target.value);
    }
    const ciudadChange= (event)=>
    {
        setCiudad(event.target.value);
    }
    const telefonoChange= (event)=>
    {
        setTelefono(event.target.value);
    }
    const mensajeChange= (event)=>
    {
      setMensaje(event.target.value);
    }
    const newStyle= useStyles();
    return (      
      <div className="contactenos">
        <br/>
        <br/>
        <br/>
        <div style={{fontSize:'30px', padding:'50px', color:'lightgray',fontWeight:'bolder'}}>Contactenos</div>
          <div className="entro">
            <form>
          <TextField onChange={nombreChange} variant="outlined" label="Nombre" className={newStyle.root}
          InputLabelProps={{className: newStyle.button}}>
            </TextField>
            <br/><br/>  <br/> 
          <TextField onChange={ciudadChange} variant="outlined" label="Ciudad" className={newStyle.root} 
          InputLabelProps={{className: newStyle.button}}></TextField><br/><br/>  <br/>           
          <TextField onChange={telefonoChange} variant="outlined" label="Teléfono" className={newStyle.root}
          InputLabelProps={{className: newStyle.button}}></TextField><br/><br/>  <br/>   
          <TextField variant="outlined" label="Tipo de solicitud" select style={{width:'210px'}}
          onChange={handleChange} className={newStyle.root} InputLabelProps={{className: newStyle.button}}>
                <MenuItem value="Peticiones">Peticiones</MenuItem>
                <MenuItem value="Quejas">Quejas</MenuItem>
                <MenuItem value="Reclamos">Reclamos</MenuItem>
                <MenuItem value="Solicitud">Solicitudes de servicio</MenuItem>
            </TextField><br/><br/><br/>
            <TextField onChange={mensajeChange} variant="outlined" label="Mensaje" style={{width:'70%'}} className={newStyle.root}
            InputLabelProps={{className: newStyle.button}}></TextField>
            <Button variant="outlined" style={{marginLeft:'10px'}} onClick={enviarCorreo} className={newStyle.button}>Enviar</Button>
            </form>
            </div>
            <div style={{widht:'50%', textAlign:'center', fontWeight:'bolder', marginTop:'70px', opacity:'0.8', fontSize:'13px'}}>
              Contactenos: (313) 600 - 47 - 00 - <br/> Dirección: Cll 10 # 11 - 72</div>
      </div>
    );
  }
  
  export default Contactenos;